<template>
  <img
    :src="localItem.attached"
    :alt="localItem.attached"
    class="a-report-image"
    v-if="localItem.attached"
  />
  <span class="a-input-text-icon">
    <img :src="require('@/assets/icons/secretaries.svg')" />
    <InputText
      v-if="localItem.secretary"
      type="text"
      v-model="localItem.secretary.name"
      placeholder="Secretaría"
      disabled
    />
  </span>

  <span class="a-input-text-icon">
    <img :src="require('@/assets/icons/message.svg')" />
    <Textarea
      v-if="localItem.users"
      v-model="localItem.users[0].description"
      :autoResize="true"
      rows="5"
      cols="30"
      placeholder="Descripción"
      disabled
    />
  </span>

  <span class="a-input-text-icon">
    <img :src="require('@/assets/icons/email.svg')" />
    <InputText
      v-if="localItem.users"
      type="text"
      v-model="localItem.users[0].user.email"
      placeholder="Correo electrónico"
      disabled
    />
  </span>
  <span class="a-input-text-icon" v-if="localItem.users > 0">
    <img :src="require('@/assets/icons/email.svg')" />
    <InputText
      v-if="localItem.users[0].user.userData"
      type="text"
      v-model="localItem.users[0].user.userData.mobilePhone"
      placeholder="Correo electrónico"
      disabled
    />
  </span>

  <span class="a-input-text-icon">
    <img :src="require('@/assets/icons/location.svg')" />
    <InputText
      v-if="localItem.address"
      type="text"
      v-model="localItem.address"
      placeholder="Dirección"
      disabled
    />
  </span>

  <span class="a-input-text-icon">
    <img :src="require('@/assets/icons/date.svg')" />
    <InputText
      v-if="localItem.created_at"
      type="text"
      v-model="formatDateCreated"
      placeholder="Dirección"
      disabled
    />
  </span>
  <span class="a-input-text-icon">
    <img :src="require('@/assets/icons/user.svg')" />
    <InputText
      v-if="localItem.counter"
      type="text"
      v-model="localItem.counter"
      placeholder="Dirección"
      disabled
    />
  </span>
  <Toast />
</template>

<script>
export default {
  name: "Detail",
  props: {
    item: Object,
  },
  data() {
    return {
      localItem: {
        active: true,
      },
      isLoading: false,
      hidden: true,
    };
  },
  computed: {
    formatDateCreated() {
      var options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour12: true,
      };
      return new Date(this.item.created_at).toLocaleDateString(
        "es-ES",
        options
      );
    },
  },
  mounted() {
    this.localItem = this.item;
  },
};
</script>

<style>
.a-report-image {
  max-width: 100% !important;
  object-fit: contain;
  border-radius: 10px;
  max-height: 250px !important;
}
</style>
