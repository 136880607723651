<template>
  <main-container>
    <!-- TOP -->
    <template v-slot:header>
      <div class="a-search-container">
        <button class="a-icon-btn" @click="exportCSV">
          <div>
            <img :src="require('@/assets/icons/export.svg')" height="24" />
          </div>
        </button>
        <InputText placeholder="Buscar" v-model="filters['global'].value" />
      </div>
    </template>

    <TabView>
      <TabPanel
        v-for="datakey in datasetTypes"
        :key="datakey"
        :header="datakey"
      >
        <!-- Table -->
        <DataTable
          :value="dataset[datakey]"
          :paginator="true"
          :rows="7"
          paginatorTemplate="PrevPageLink PageLinks NextPageLink"
          :filters="filters"
          :globalFilterFields="['incidentType.name', 'created_at', 'id']"
        >
          <!-- Id field -->
          <Column field="id" header="Id." sortable headerStyle="width: 5rem" />
          <!-- Fields defined in data section -->
          <Column
            v-for="col of fields"
            :field="col.field"
            :header="col.header"
            :key="col.field"
            sortable
          >
          </Column>

          <Column field="created_at" header="Fecha" sortable>
            <template #body="slotProps">
              {{ formatDate(slotProps.data.created_at) }}
            </template>
          </Column>

          <!-- State field -->
          <Column header="Estado" v-if="datakey != 'Pendientes'">
            <template #body="slotProps">
              <span
                :style="`background: ${slotProps.data.status.color}`"
                class="a-id-badge a-status-badge"
              >
                {{ slotProps.data.status.name }}</span
              >
            </template>
          </Column>

          <!-- Action buttons -->
          <Column headerStyle="width: 9rem">
            <template #body="slotProps">
              <div class="a-container-tab-btns">
                <button
                  class="a-icon-tab-btn a-detail-btn"
                  @click="detailDataset(slotProps.data)"
                >
                  <img :src="require('@/assets/icons/eye-white.svg')" />
                </button>
                <button
                  class="a-icon-tab-btn a-answer-btn"
                  @click="
                    answerIncident(
                      slotProps.data,
                      datakey != 'Pendientes' ? false : true
                    )
                  "
                >
                  <img :src="require('@/assets/icons/message.svg')" />
                </button>
              </div>
            </template>
          </Column>
        </DataTable>
      </TabPanel>
    </TabView>
  </main-container>

  <Dialog
    v-model:visible="settings.answerModal"
    v-if="settings.answerModal"
    :modal="true"
    :breakpoints="{ '960px': '85vw' }"
    :style="{ width: '70vw' }"
  >
    <template #header>
      <h3>
        Reporte
        <span class="a-id-badge" v-if="settings.selected != null">{{
          settings.selected.id
        }}</span>
      </h3>
    </template>
    <Answer
      :item="settings.selected"
      :user="user.id"
      :isAnswerAllowed="!settings.isPendant"
      @stateAdded="onStateChanged"
    />
  </Dialog>

  <Dialog
    v-model:visible="settings.detailModal"
    v-if="settings.detailModal"
    :modal="true"
    :breakpoints="{ '960px': '85vw' }"
    :style="{ width: '70vw' }"
  >
    <template #header>
      <h3>
        Reporte
        <span class="a-id-badge" v-if="settings.selected != null">{{
          settings.selected.id
        }}</span>
      </h3>
    </template>
    <Detail :item="settings.selected" />
  </Dialog>
</template>

<script>
import MainContainer from "../components/templates/MainContainer.vue";
import Detail from "@/components/report/DetailReport.vue";
import Answer from "@/components/report/AnswerReport.vue";
import ReportsAPI from "@/services/reports.js";
import UserAPI from "@/services/users.js";
import { FilterMatchMode } from "primevue/api";
export default {
  components: { MainContainer, Detail, Answer },
  name: "Reports",
  data() {
    return {
      dataset: {
        Activos: [],
        Solucionados: [],
        Pendientes: [],
      },
      user: null,
      solicitude: "",
      datasetTypes: ["Pendientes", "Activos", "Solucionados"],
      fields: [
        {
          field: "incidentType.name",
          header: "Tipo",
        },
      ],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      settings: {
        detailModal: false,
        selected: null,
        answerModal: false,
        isPendant: false,
        canAnswer: false,
      },
    };
  },
  computed: {
    csvData() {
      let generalDataset = [
        ...this.dataset.Activos,
        ...this.dataset.Solucionados,
        ...this.dataset.Pendientes,
      ];
      return generalDataset.map(function (item) {
        let report = {
          id: item.id,
          estado:
            item.responseComplaint.length > 0 ? item.status.name : "Pendiente",
          tipo: item.incidentType ? item.incidentType.name : "N.A.",
          secretaría: item.secretary ? item.secretary.name : "N.A.",
          fecha: item.created_at,
        };
        if (item.status) {
          report.estado = item.status.name;
        }
        return report;
      });
    },
  },
  methods: {
    formatDate(value) {
      var options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return new Date(value).toLocaleDateString("es-ES", options);
    },
    initState() {
      this.getUsertype()
        .then(() => {
          this.getDataset();
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            let isTokenError = false;
            errors.forEach((err) => {
              message += response[err];
              if (err == "code") {
                if (response[err] == "token_not_valid") {
                  isTokenError = true;
                  UserAPI.updateToken().then((response) => {
                    sessionStorage.setItem("cfta", response.data.access);
                    this.initState();
                  });
                }
              }
            });
            if (!isTokenError) {
              this.$toast.add({
                severity: "error",
                summary: "Ha ocurrido un error",
                detail: message,
                life: 3000,
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },
    getUsertype() {
      return new Promise((resolve, reject) => {
        UserAPI.show(this.$store.state.user.pk)
          .then((response) => {
            this.user = response.data;
            if (this.user.userType.name == "Editor") {
              this.solicitude = this.user.secretary.id;
              resolve();
            } else if (this.user.userType.name == "Administrador") {
              this.solicitude = "";
              resolve();
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getDataset() {
      ReportsAPI.showBySecretary(this.solicitude)
        .then((response) => {
          response.data.forEach((element, index) => {
            if (element.responseComplaint.length == 0) {
              this.dataset.Pendientes.push(element);
            } else if (element.responseComplaint.length > 0) {
              response.data[index].status =
                element.responseComplaint[
                  element.responseComplaint.length - 1
                ].workFlowStep.step;
              let isSolved = false;
              let isRedirected = false;
              let isAccepted = false;
              element.responseComplaint.forEach((response) => {
                if (response.workFlowStep.step.name == "Solucionado") {
                  isSolved = true;
                } else if (response.workFlowStep.step.name == "Redirigido") {
                  isRedirected = true;
                } else if (response.workFlowStep.step.name == "Aceptado") {
                  isAccepted = true;
                }
              });

              if (isSolved) {
                this.dataset.Solucionados.push(element);
              } else if (isRedirected && !isAccepted) {
                this.dataset.Pendientes.push(element);
              } else {
                this.dataset.Activos.push(element);
              }
            }
          });
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            let isTokenError = false;
            errors.forEach((err) => {
              message += response[err];
              if (err == "code") {
                if (response[err] == "token_not_valid") {
                  isTokenError = true;
                  UserAPI.updateToken().then((response) => {
                    sessionStorage.setItem("cfta", response.data.access);
                    this.getDataset();
                  });
                }
              }
            });
            if (!isTokenError) {
              this.$toast.add({
                severity: "error",
                summary: "Ha ocurrido un error",
                detail: message,
                life: 3000,
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },
    detailDataset(data) {
      this.settings.selected = data;
      this.settings.detailModal = true;
    },
    answerIncident(data, isPendant) {
      console.log(isPendant);
      this.settings.selected = data;
      this.settings.isPendant = isPendant;
      this.settings.answerModal = true;
    },
    onStateChanged() {
      this.settings.selected = null;
      this.settings.isPendant = false;
      this.settings.answerModal = false;
    },
    exportCSV() {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(this.csvData[0]).join(";"),
        ...this.csvData.map((item) => Object.values(item).join(";")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "reportes.csv");
      link.click();
    },
  },
  mounted() {
    this.initState();
  },
};
</script>

<style>
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background: none;
  font-weight: normal;
  color: #b2b2b2;
  font-size: 15px;
  border-color: transparent transparent white transparent !important;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: none;
  color: var(--color-3);
  font-weight: 700;
  border-color: transparent transparent var(--color-3) transparent !important;
}
.p-tabview .p-tabview-nav {
  border-bottom: none;
}
.p-tabview .p-tabview-panels {
  background: none !important;
}
.a-status-badge {
  font-weight: 400;
  font-size: 16px;
  margin-left: 0;
  padding: 5px 10px;
}
</style>
