<template>
  <div class="a-announcement" v-if="!isAnswerAllowed && !isRejectIncident">
    <span>
      Este reporte se muestra a la secretaría asignada de acuerdo con su tipo de
      incidente o alguna redirección realizada por otra secretaría.
    </span>
    <br />
    <span class="a-highlight">¿Deseas aceptar el incidente?</span>
    <div id="a-row-buttons">
      <Button
        label="Rechazar"
        class="p-button-raised p-button-danger p-button-text"
        @click="isRejectIncident = true"
      />

      <button
        @click="acceptIncident"
        :class="!isLoading ? 'a-main-btn' : 'a-main-btn a-loading-btn'"
        :disabled="isLoading"
      >
        Aceptar
      </button>
    </div>
  </div>

  <div v-if="!isAnswerAllowed && isRejectIncident">
    <div class="p-mb-3">
      Se redirigirá el proyecto a otra secretaría. Escoge una dependencia y
      cuéntanos por qué rechazas el incidente.
      <!-- Secretaría -->
      <Dropdown
        v-model="response.secretary"
        :options="secretary"
        optionLabel="name"
        placeholder="Selecciona la secretaría"
        :showClear="true"
      />
      <!-- Descripcion -->
      <Textarea
        v-if="response.secretary != null"
        v-model="response.description"
        :autoResize="true"
        placeholder="Agrega una descripción"
        rows="5"
        cols="30"
      />
      <button
        :class="!isLoading ? 'a-main-btn' : 'a-main-btn a-loading-btn'"
        :disabled="isLoading"
        @click="rejectIncident"
      >
        Redirigir
      </button>
    </div>
  </div>

  <!-- Dar respuesta -->
  <div v-if="isAnswerAllowed">
    <div id="a-response-btn">
      <div class="p-mb-3">
        Selecciona la respuesta y escribe una descripción.
      </div>
      <!-- Estados de respuesta -->
      <Dropdown
        v-model="response.step"
        :options="history"
        optionLabel="name"
        placeholder="Selecciona la respuesta"
        :showClear="true"
      />
      <!-- Descripcion -->
      <Textarea
        v-if="response.step != null"
        v-model="response.description"
        :autoResize="true"
        placeholder="Agrega una descripción"
        rows="5"
        cols="30"
      />

      <button
        :class="!isLoading ? 'a-main-btn' : 'a-main-btn a-loading-btn'"
        :disabled="isLoading"
        @click="setState(response.step.id)"
      >
        Aceptar
      </button>
    </div>
  </div>

  <!-- Ver historial -->
  <Fieldset
    legend="Respuestas"
    toggleable
    :collapsed="false"
    v-if="item.status"
  >
    <Timeline :value="item.responseComplaint">
      <template #content="slotProps">
        <div class="a-response">
          <span>Actualización {{ formatDate(slotProps.item.created_at) }}</span>
          <br /><br />
          <span>
            Cambio de estado a
            <strong> {{ slotProps.item.workFlowStep.step.name }}</strong>
          </span>
          <br /><br />
          <span>
            Realizado por
            <strong v-if="slotProps.item.secretary">
              {{ slotProps.item.complaint.secretary.name }}
            </strong></span
          >
          <br /><br />
          <span>
            <strong>"</strong> {{ slotProps.item.response }}
            <strong>"</strong>
          </span>
        </div>
      </template>
    </Timeline>
  </Fieldset>
</template>

<script>
import HistoryAPI from "@/services/history.js";
import SecretaryAPI from "@/services/secretaries.js";
import UserAPI from "@/services/users.js";
import ReportsAPI from "@/services/reports.js";
export default {
  props: {
    item: Object,
    user: Number,
    isAnswerAllowed: Boolean,
  },
  data() {
    return {
      isRejectIncident: false,
      isLoading: false,
      history: [],
      secretary: [],
      response: {
        step: null,
        secretary: null,
        description: "",
      },
    };
  },
  emits: ["stateAdded"],
  methods: {
    formatDate(value) {
      var options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return new Date(value).toLocaleDateString("es-ES", options);
    },
    getHistoryDataset() {
      HistoryAPI.index()
        .then((response) => {
          this.history = response.data;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            let isTokenError = false;
            errors.forEach((err) => {
              message += response[err];
              if (err == "code") {
                if (response[err] == "token_not_valid") {
                  isTokenError = true;
                  UserAPI.updateToken().then((response) => {
                    sessionStorage.setItem("cfta", response.data.access);
                    this.getDataset();
                  });
                }
              }
            });
            if (!isTokenError) {
              this.$toast.add({
                severity: "error",
                summary: "Ha ocurrido un error",
                detail: message,
                life: 3000,
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },
    getSecretaryDataset() {
      SecretaryAPI.index()
        .then((response) => {
          this.secretary = response.data;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            let isTokenError = false;
            errors.forEach((err) => {
              message += response[err];
              if (err == "code") {
                if (response[err] == "token_not_valid") {
                  isTokenError = true;
                  UserAPI.updateToken().then((response) => {
                    sessionStorage.setItem("cfta", response.data.access);
                    this.getSecretaryDataset();
                  });
                }
              }
            });
            if (!isTokenError) {
              this.$toast.add({
                severity: "error",
                summary: "Ha ocurrido un error",
                detail: message,
                life: 3000,
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },
    setState() {
      ReportsAPI.newStepComplaint(
        this.item.responseComplaint[0].workFlowStep.workFlow.id,
        this.response.step.id
      ).then((workflowstep) => {
        ReportsAPI.newResponseComplaint(
          workflowstep.data.id,
          this.response.description,
          this.item.id
        ).then(() => {
          this.$emit("stateAdded");
        });
      });
    },
    rejectIncident() {
      if (
        this.item.responseComplaint.length == 0 ||
        (this.item.status && this.item.status.name == "Redirigido")
      ) {
        ReportsAPI.newWorkflowComplaint(this.user, this.item.id).then(
          (workflow) => {
            ReportsAPI.newStepComplaint(workflow.data.id, 10).then(
              (workflowStep) => {
                ReportsAPI.newResponseComplaint(
                  workflowStep.data.id,
                  this.response.description,
                  this.item.id
                ).then(() => {
                  let complaint = this.item;
                  delete complaint.latitude;
                  delete complaint.longitude;
                  complaint.secretary = this.response.secretary.id;
                  console.log(complaint);
                  ReportsAPI.update(this.item.id, complaint).then(() =>
                    this.$emit("stateAdded")
                  );
                });
              }
            );
          }
        );
      } else {
        ReportsAPI.newStepComplaint(
          this.item.responseComplaint[0].workFlowStep.workFlow.id,
          10
        ).then((workflowstep) => {
          ReportsAPI.newResponseComplaint(
            workflowstep.data.id,
            this.response.description,
            this.item.id
          ).then(() => {
            this.$emit("stateAdded");
          });
        });
      }
    },
    acceptIncident() {
      if (this.item.responseComplaint.length == 0) {
        ReportsAPI.newWorkflowComplaint(this.user, this.item.id).then(
          (workflow) => {
            ReportsAPI.newStepComplaint(workflow.data.id, 9).then(
              (workflowStep) => {
                ReportsAPI.newResponseComplaint(
                  workflowStep.data.id,
                  "La secretaría ha aceptado el reporte.",
                  this.item.id
                ).then(() => {
                  this.$emit("stateAdded");
                });
              }
            );
          }
        );
      } else {
        ReportsAPI.newStepComplaint(
          this.item.responseComplaint[0].workFlowStep.workFlow.id,
          9
        ).then((workflowstep) => {
          ReportsAPI.newResponseComplaint(
            workflowstep.data.id,
            "La secretaría ha aceptado el reporte.",
            this.item.id
          ).then(() => {
            this.$emit("stateAdded");
          });
        });
      }
    },
  },
  mounted() {
    this.getHistoryDataset();
    this.getSecretaryDataset();
  },
};
</script>

<style scoped>
#a-row-buttons {
  display: flex;
  gap: 12px;
}
</style>
