import Axios from "axios";

let http = "https://app.gobiernocontigo.com/api/v1/";

export default {
  index() {
    return Axios.get(`${http}complaintv2/`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("cfta")}`,
      },
    });
  },

  // show(id) {},
  showBySecretary(secretary) {
    return Axios.get(`${http}complaintv2/?secretary=${secretary}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("cfta")}`,
      },
    });
  },

  update(id, data) {
    for (const key in data) {
      if (typeof data[key] == "object" && data[key] != null) {
        data[key] = data[key].id;
      }
    }
    if (typeof data.attached == "string") {
      delete data.attached;
    }
    return Axios.put(`${http}complaintv2/${id}/`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("cfta")}`,
      },
    });
  },

  delete(data) {
    data.active = false;
    return Axios.put(`${http}complaintv2/${data.id}/`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("cfta")}`,
      },
    });
  },

  newWorkflowComplaint(user, complaint) {
    console.log(user);
    console.log(complaint);
    return Axios.post(
      `${http}workflow/`,
      {
        user: user,
        complaint: complaint,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("cfta")}`,
        },
      }
    );
  },

  newStepComplaint(workFlow, step) {
    return Axios.post(
      `${http}workflowstep/`,
      {
        workFlow: workFlow,
        step: step,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("cfta")}`,
        },
      }
    );
  },

  newResponseComplaint(workflowStep, description, complaint) {
    return Axios.post(
      `${http}responsecomplaint/`,
      {
        workFlowStep: workflowStep,
        response: description,
        complaint: complaint,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("cfta")}`,
        },
      }
    );
  },
};
